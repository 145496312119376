/* stylelint-disable */

@mixin btn-reset {
  border: none;
  padding: 0;
  font-family: inherit;
  background: transparent;
  cursor: pointer;
}

@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: transparent;
}

@mixin bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin flex {
  display: flex;
}

@mixin flex-v-center {
  display: flex;
  align-items: center;
}

@mixin flex-h-center {
  display: flex;
  justify-content: center;
}

@mixin flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-all-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin footerToBottom {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

@mixin mr($value) {
  &:not(:last-child) {
    margin-right: $value;
  }
}

@mixin ml($value) {
  &:not(:last-child) {
    margin-left: $value;
  }
}

@mixin mb($value) {
  &:not(:last-child) {
    margin-bottom: $value;
  }
}

@mixin mt($value) {
  &:not(:last-child) {
    margin-top: $value;
  }
}

@mixin pseudo {
  content: '';
  display: block;
}

@mixin tracking {
  letter-spacing: -0.04em;
}

@mixin linear-text($color: var(--prim-button)) {
  background: $color;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin bgIcon($path) {
  background-image: url('/images/icons/#{$path}.svg');
}

@mixin section-offset {
  padding-bottom: 120px;

  @include ipad {
    padding-bottom: 90px;
  }

  @include mobile {
    padding-bottom: 60px;
  }
}

@mixin expand-container {
  padding-left: var(--container-offset);
  padding-right: var(--container-offset);
  margin-left: calc(var(--container-offset) / -100 * 100);
  margin-right: calc(var(--container-offset) / -100 * 100);
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
