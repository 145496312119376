@import '@/shared/scss';

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh, 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--block-dark);
  z-index: var(--menu-z-index);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity var(--duration),
    visibility var(--duration);

  &.isOpened {
    opacity: 1;
    visibility: visible;
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 32px;
  width: 32px;
  height: 32px;
  transition: transform var(--duration);

  @include mobile {
    top: 16px;
    right: 16px;
  }

  @include hover {
    transform: scale(1.15);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.list {
  display: flex;
  row-gap: 48px;
  flex-direction: column;
  align-items: center;

  @include mobile {
    row-gap: 32px;
  }
}

.link {
  color: var(--txt-primari-wight);
  text-align: center;
  font-size: 32px;
  line-height: 120%;
  font-weight: 600;

  @include mobile {
    font-size: 24px;
  }

  @include hover {
    @include linear-text(var(--prim-button));
  }
}

.decor1 {
  @include responsive-property(width, 322 / 2);
  @include responsive-property(height, 306 / 2);
  @include responsive-property(left, 64);
  @include responsive-property(bottom, 12);

  @include mobile {
    width: 80px;
    height: 77px;
    left: -32px;
    bottom: 137px;
    transform: rotate(-5deg);
    filter: blur(3px);
  }
}

.decor2 {
  @include responsive-property(width, 264 / 2);
  @include responsive-property(height, 236 / 2);
  @include responsive-property(right, 193);
  top: 19px;

  @include mobile {
    top: 95px;
    width: 67px;
    height: 67px;
    right: initial;
    left: calc(50% + 12px);
    transform: translateX(-50%);
    filter: blur(2px);
  }
}

.decor3 {
  @include responsive-property(width, 292 / 2);
  @include responsive-property(height, 238 / 2);
  @include responsive-property(bottom, -40);
  @include responsive-property(right, 129);

  @include mobile {
    display: none;
  }
}

.decor4 {
  @include responsive-property(width, 176 / 2);
  @include responsive-property(height, 158 / 2);
  @include responsive-property(bottom, 12);
  left: calc(50% - 20px);

  @include mobile {
    width: 46px;
    height: 40px;
    left: calc(50% - 22px);
    bottom: 24px;
  }
}

.decor5 {
  @include responsive-property(width, 176 / 2);
  @include responsive-property(height, 158 / 2);
  @include responsive-property(right, 127);
  @include responsive-property(top, 293);

  @include mobile {
    display: none;
  }
}

.decor6 {
  @include responsive-property(width, 432 / 2);
  @include responsive-property(height, 454 / 2);
  top: 0;
  left: 0;

  @include mobile {
    width: 121px;
    height: 121px;
    filter: blur(3px);
  }
}

.decor7 {
  @include responsive-property(width, 173 / 2);
  @include responsive-property(height, 176 / 2);
  @include responsive-property(left, 131);
  @include responsive-property(bottom, 317);

  @include mobile {
    display: none;
  }
}

.decor8 {
  @include responsive-property(width, 431 / 2);
  @include responsive-property(height, 408 / 2);
  @include responsive-property(left, 221);
  @include responsive-property(top, 119);

  @include mobile {
    left: auto;
    top: 250px;
    right: -70px;
    width: 121px;
    height: 115px;
  }
}

.decor9 {
  @include responsive-property(width, 276 / 2);
  @include responsive-property(height, 272 / 2);
  @include responsive-property(right, 201);
  @include responsive-property(bottom, 272);

  @include mobile {
    width: 120px;
    height: 120px;
    right: -35px;
    bottom: 80px;
    transform: rotate(140deg);
  }
}
