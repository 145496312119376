@import '@/shared/scss';

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  font-weight: 700;
  font-size: 16px;
  line-height: 17.6px;
  text-transform: uppercase;
  height: 54px;

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--button-txt-dark);
    width: 100%;
    height: 50px;
    border-radius: 4px;
    transition:
      height var(--duration),
      color var(--duration),
      font-size var(--duration),
      box-shadow var(--duration);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      top: 0;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &:before {
      background: var(--prim-button);
    }

    &:after {
      opacity: 0;
      z-index: -1;
      border: 2px solid #ffd493cc;
      background: linear-gradient(90deg, #ffba2f 0%, #ffd159 100%);
      transition: opacity var(--duration);
    }
  }

  &:hover {
    .content {
      height: 54px;
      font-size: 18px;
      color: var(--button-txt-dark);
      box-shadow: 0px 0px 32px 0px #ffda7e99;

      &:after {
        opacity: 1;
      }
    }
  }

  &[hidden] {
    display: none;
  }
}

.outlined {
  .content {
    color: var(--txt-secondary-wight);

    &:before {
      border: 1px solid var(--border-lght);
      background: transparent;
    }
  }
}
