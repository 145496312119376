@import '@/shared/scss';

:root {
  // base
  --font-family: 'Roboto Flex', sans-serif;

  // container
  --content-width: 1224px;
  --container-offset: 25px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // gradients
  --block-light: linear-gradient(0deg, #e2e7ef 0%, #f8f9fd 100%);
  --block-dark: linear-gradient(180deg, #17293d 0%, #111821 100%);
  --prim-button: linear-gradient(90deg, #fdc453 0%, #f8c43e 100%);
  --cta-prinary: linear-gradient(90deg, #4d86ba 0%, #2c82e7 100%);

  // colors
  --action-ellow: #fcc636;
  --txt-primari-wight: #f8f9ff;
  --txt-secondary-wight: #e0e4ed;
  --txt-secondary-black: #172143;
  --button-txt-dark: #0d2634;
  --border-lght: #d0dbec;
  --border-dark: #2c343f;

  // other
  --min-width: 320px;
  --duration: 0.2s;
  --radius: 8px;

  // z-index
  --header-z-index: 100;
  --menu-z-index: 1000;

  @include ipad {
    --container-offset: 16px;
  }
}
