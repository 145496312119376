.root {
  position: relative;
  display: flex;
  align-items: center;
  transform: translateY(-2px);

  &:hover {
    .arrow {
      transform: rotate(180deg);
    }
  }

  &::after {
    content: '';
    display: block;
    margin-left: 4px;
    width: 1px;
    height: 20px;
    background-color: #a4a4a4;
  }
}

.current {
  display: flex;
  gap: 4.5px;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.name {
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
}

.arrow {
  display: block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: #93a6bd;
  transition: transform var(--duration);
}

.dropdown {
  position: absolute;
  top: calc(100% + 12px);
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  border-radius: 4px;
  background: var(--block-dark);
  padding: 12px;
  width: 99px;
  right: -9px;

  &[hidden] {
    display: none;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    color: var(--action-ellow);
  }
}

.currentItem {
  grid-area: var(--grid-area);
  color: var(--action-ellow);
  cursor: default;
}
