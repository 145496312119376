@import '@/shared/scss';

.row {
  display: flex;
  gap: 12px;
  align-items: center;
  min-height: 54px;

  &[hidden] {
    display: none;
  }
}

.close {
  display: block;
  width: 40px;
  height: 40px;
  transition: transform var(--duration);

  @include hover {
    transform: scale(1.15);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
