.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
}

.copy {
  line-height: 120%;
  color: var(--txt-secondary-wight);
}
