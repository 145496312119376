@import '@/shared/scss';

.container {
  padding-bottom: 120px;

  @include mobile {
    padding-bottom: 65px;
  }
}

.title {
  margin-bottom: 39px;

  @include mobile {
    margin-bottom: 30px;
  }
}

.list {
  display: grid;
  gap: 40px;
  column-gap: 36px;
  grid-template-columns: repeat(3, 1fr);

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include ipad {
    gap: 32px;
    grid-template-columns: 1fr;
  }

  @include mobile {
    margin-bottom: 40px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background: var(--cta-prinary);

  @include mobile {
    width: 64px;
    height: 64px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.label {
  font-size: 18px;
  line-height: 120%;
  color: var(--txt-secondary-wight);
  font-weight: 300;

  @include mobile {
    font-size: 16px;
  }
}

.certified {
  position: absolute;
  top: -17px;
  right: 0;
  display: flex;
  align-items: center;

  @include mobile {
    position: static;
    justify-content: flex-end;
  }
}

.certifiedIcon {
  position: relative;
  top: -7px;
  z-index: 2;
  display: block;
  flex-shrink: 0;
  width: 90px;
  height: 86px;
  margin-right: -50px;
}

.certifiedLabel {
  display: block;
  font-size: 18px;
  line-height: 120%;
  white-space: nowrap;
  color: #45a348;
  text-transform: uppercase;
  font-weight: 700;
  padding: 16px 62px;
  padding-right: 24px;
  background: var(--block-dark);
}

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 33px;
  }
}

.benefitsItem {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  background: var(--block-light);
  padding: 48px 10px 47px;

  @include ipad {
    justify-content: flex-start;
    padding: 16px 2px;
  }

  @include mobile {
    padding: 16px 2px 11px;
  }

  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--border-dark);
    }
  }
}

.benefitsQuantity {
  @include linear-text(var(--cta-prinary));
  text-align: center;

  @include mobile {
    font-size: 24px;
  }
}

.benefitsLabel {
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  color: var(--txt-secondary-black);
  text-align: center;

  @include mobile {
    font-size: 16px;
  }
}

.btn {
  display: block;
  margin: 0 auto;

  @include mobile {
    min-width: calc(100% - calc(var(--container-offset)) * 2);
  }
}

.toggleRow {
  justify-content: center;
}

.coin {
  @include responsive-property(width, 92);
  @include responsive-property(height, 78);
  left: 140px;
  bottom: 0;
  transform: rotate(5deg) translateY(var(--transform)) scaleX(-1);

  @include ipad {
    display: none;
  }
}
