@import '@/shared/scss';

.container {
  display: flex;
  flex-direction: column;

  @include mobile {
    row-gap: 123px;
  }
}

.coin1 {
  @include responsive-property(width, 229);
  @include responsive-property(height, 180);
  top: 390px;
  right: -90px;
  transform: translateY(var(--transform)) rotate(42deg);

  @include ipad {
    display: none;
  }
}

.coin2 {
  @include responsive-property(width, 98);
  @include responsive-property(height, 77);
  top: 860px;
  left: calc(50% + 16px);
  transform: translateX(-50%) translateY(var(--transform)) rotate(-15deg);

  @include ipad {
    display: none;
  }
}

.diamond {
  @include responsive-property(width, 200);
  @include responsive-property(height, 170);
  bottom: -170px;
  left: calc(50% - 150px);
  transform: translateX(-50%) translateY(var(--transform));

  @include tablet {
    bottom: -100px;
  }

  @include ipad {
    display: none;
  }
}
