@import '@/shared/scss';

.h1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 110%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  @include linear-text();

  @include laptop {
    font-size: 72px;
  }

  @include tablet {
    font-size: 65px;
  }

  @include ipad {
    font-size: 50px;
  }

  @include mobile {
    font-size: 36px;
    letter-spacing: -0.01em;
  }
}

.h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;

  @include mobile {
    font-size: 32px;
    letter-spacing: -0.02em;
  }
}

.p {
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: var(--txt-secondary-wight);

  @include mobile {
    font-size: 20px;
    line-height: 140%;
  }
}
