@import '@/shared/scss';

.root {
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;

    @include ipad {
      display: none;
    }
  }

  &:before {
    top: -229px;
    left: -161px;
    width: 1020px;
    height: 1055px;
    background-image: url('../../../public/images/blur-backgrounds/4.png');

    @include tablet {
      top: -250px;
      left: -200px;
      transform: scale(0.7);
    }
  }

  &:after {
    right: 0;
    bottom: 0;
    width: 366px;
    height: 578px;
    background-image: url('../../../public/images/blur-backgrounds/5.png');
  }
}

.container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 214px;
  padding-bottom: 140px;

  @include laptop {
    padding-top: 140px;
    padding-bottom: 120px;
  }

  @include ipad {
    display: block;
    padding-top: 550px;
    padding-bottom: 100px;
  }

  @include mobile {
    padding-top: 402px;
    padding-bottom: 80px;
  }
}

.content {
  max-width: 546px;
  transform: translateY(-22px);

  @include ipad {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
    transform: translateY(0);
  }
}

.title,
.desc {
  @include ipad {
    text-align: center;
  }
}

.banner {
  position: absolute;
  top: 88px;
  width: 85%;
  height: 76%;
  left: 0;
  right: 0;
  margin: 0 auto;

  @include tablet {
    top: 0;
    height: 100%;
    width: 100%;
  }

  @include ipad {
    top: 80px;
    left: auto;
    right: 70px;
    transform: none;
    width: 768px;
    height: 450px;
  }

  @media (max-width: 640px) {
    right: 17px;
  }

  @include mobile {
    height: 298px;
    width: 513px;
  }
}

.title {
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 17px;
  }
}

.desc {
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  margin-bottom: 40px;

  @include mobile {
    font-size: 20px;
    margin-bottom: 24px;
  }
}

.btn {
  @include ipad {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
}

.toggleRow {
  @include ipad {
    justify-content: center;
  }
}
