@import '@/shared/scss';

.section {
  padding-top: 49px;

  @include mobile {
    padding-top: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 100px;
    width: 307px;
    height: 538px;
    background-image: url('../../../public/images/blur-backgrounds/8.png');
    background-repeat: no-repeat;
    background-size: cover;

    @include mobile {
      bottom: 300px;
    }
  }
}

.container {
  position: relative;
}

.title {
  margin-bottom: 41px;

  @include mobile {
    text-align: center;
    margin-bottom: 30px;
  }
}

.btn {
  @include min-mobile {
    position: absolute;
    top: -1px;
    right: var(--container-offset);
  }

  @include mobile {
    min-width: 100%;
    margin-top: 33px;
  }
}

.list {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include ipad {
    display: flex;
    flex-direction: column;
  }

  @include mobile {
    gap: 16px;
  }
}

.item {
  position: relative;
  padding: 30px 16px;
  background: var(--block-dark);
  border-radius: 8px;

  @include mobile {
    padding: 16px;
  }
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.logo {
  display: block;
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 16px;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.itemContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.itemTitle {
  font-style: italic;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 30px;

  @include mobile {
    font-size: 24px;
    padding: 16px 0;
    margin-bottom: 0;
  }
}

.itemBtn {
  position: relative;
  z-index: 10;

  @include mobile {
    display: none;
  }
}

.toggleRow {
  @include mobile {
    display: none;
  }
}

.coin {
  @include responsive-property(width, 116);
  @include responsive-property(height, 92);
  bottom: -134px;
  left: calc(50% + 11px);
  transform: translateX(-50%) rotate(-14deg) translateY(var(--transform));

  @include ipad {
    display: none;
  }
}
